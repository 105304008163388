import { window } from 'ssr-window';

export default function (breakpoints) {
  // Get breakpoint for window width
  if (!breakpoints) return undefined;
  let breakpoint = false;

  const points = Object.keys(breakpoints).map((point) => {
    if (typeof point === 'string' && point.startsWith('@')) {
      const minRatio = parseFloat(point.substr(1));
      const value = window.innerHeight * minRatio;
      return { value, point };
    }
    return { value: point, point };
  });

  points.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
  for (let i = 0; i < points.length; i += 1) {
    const { point, value } = points[i];
    if (value <= window.innerWidth) {
      breakpoint = point;
    }
  }
  return breakpoint || 'max';
}
